import React from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";

import { ConversionKeys_conversionKeys_edges_node } from "../../generated/ConversionKeys";

export interface IConversionKeyListProps {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  conversionKeys: ConversionKeys_conversionKeys_edges_node[];
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const ConversionKeyList = ({
  hasNextPage,
  hasPreviousPage,
  conversionKeys,
  onNextPage,
  onPreviousPage
}: IConversionKeyListProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5">Conversion Keys</Typography>
      </div>
      <Typography variant="body1">Conversion keys can be used when sending conversions via S2S endpoint</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Key</TableCell>
            <TableCell>Last Used</TableCell>
            <TableCell>Created By</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {conversionKeys.map(conversionKey => (
            <TableRow key={conversionKey.id}>
              <TableCell>{conversionKey.name}</TableCell>
              <TableCell>{conversionKey.id}</TableCell>
              <TableCell>{conversionKey.lastUsedAt}</TableCell>
              <TableCell>{conversionKey.createdBy.name} ({conversionKey.createdBy.displayId})</TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button disabled={!hasPreviousPage} onClick={onPreviousPage}>
                    {"<"}
                  </Button>
                  <Button disabled={!hasNextPage} onClick={onNextPage}>
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
};

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: { padding: 0 },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default ConversionKeyList;

import { useQuery } from "@apollo/client";
import clsx from "clsx";
import React, { MouseEvent, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem
} from "@material-ui/core";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BuildIcon from "@material-ui/icons/Build";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import LinkIcon from "@material-ui/icons/Link";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import PaymentIcon from "@material-ui/icons/Payment";
import PeopleIcon from "@material-ui/icons/People";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from "@material-ui/icons/Store";
import TimelineIcon from "@material-ui/icons/Timeline";

import { PartnerViewId as IPartnerViewId } from "../../generated/PartnerViewId";
import PartnerViewIdQuery from "../../queries/PartnerViewIdQuery";

import { useAuth0 } from "../../utils/auth0Provider";
import SidenavPopover from "../SidenavPopover";
import SidenavSubId from "../SidenavSubId";

interface ISideNavProps {
  navOpen: boolean;
}

enum ActivePopover {
  ACCOUNT = "ACCOUNT",
  ACCOUNTING = "ACCOUNTING",
  CAMPAIGNS = "CAMPAIGNS",
  REPORTS = "REPORTS",
  TECH = "TECH"
}

const Sidenav = ({ navOpen }: ISideNavProps) => {
  const classes = useStyles();

  const { hasPermission, logout } = useAuth0();
  const readAllAdjustments = hasPermission("read:adjustments");
  const readPayments = hasPermission("read:monthly_payments");

  const { data } = useQuery<IPartnerViewId>(PartnerViewIdQuery);

  const partnerId = data?.partnerViewId;

  const location = useLocation();

  const [accountOpen, setAccountOpen] = useState(
    location.pathname.includes("/settings")
  );
  const [accountingOpen, setAccountingOpen] = useState(
    location.pathname.includes("/adjustments") ||
      location.pathname.includes("/payments")
  );
  const [campaignsOpen, setCampaignsOpen] = useState(
    location.pathname.includes("/advertisers") ||
      location.pathname.includes("/brands") ||
      location.pathname.includes("/offers") ||
      location.pathname.includes("/offer-pools") ||
      location.pathname.includes("/account-pools") ||
      location.pathname.includes("/generator")
  );
  const [reportsOpen, setReportsOpen] = useState(
    location.pathname.includes("/reports")
  );
  const [techOpen, setTechOpen] = useState(location.pathname.includes("/tech"));

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [openPopover, setOpenPopover] = useState<ActivePopover>();

  const handleOpen = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    if (!navOpen) {
      setAnchorEl(event.currentTarget);
      setOpenPopover(event.currentTarget.id as ActivePopover);
    }
  };

  const handleClose = () => {
    setAnchorEl(undefined);
    setOpenPopover(undefined);
  };

  const renderAccountingPopoverMenu = () => (
    <SidenavPopover
      anchorEl={anchorEl}
      handleClose={handleClose}
      open={openPopover === ActivePopover.ACCOUNTING}
    >
      <MenuItem
        button
        component={Link}
        selected={location.pathname.includes("/adjustments")}
        to="/adjustments"
      >
        <ListItemIcon>
          <FormatListNumberedIcon />
        </ListItemIcon>
        <ListItemText primary="Adjustments" />
      </MenuItem>
      {readPayments && (
        <MenuItem
          button
          component={Link}
          selected={location.pathname.includes("/payments")}
          to="/payments"
        >
          <ListItemIcon>
            <PaymentIcon />
          </ListItemIcon>
          <ListItemText primary="Payments" />
        </MenuItem>
      )}
    </SidenavPopover>
  );

  const renderAccountPopoverMenu = () => (
    <SidenavPopover
      anchorEl={anchorEl}
      handleClose={handleClose}
      open={openPopover === ActivePopover.ACCOUNT}
    >
      <MenuItem
        button
        component={Link}
        selected={location.pathname.includes("/settings")}
        to="/settings"
      >
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </MenuItem>

      <MenuItem
        button
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        <ListItemIcon>
          <ClearIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </SidenavPopover>
  );

  const renderCampaignsPopoverMenu = () => (
    <SidenavPopover
      anchorEl={anchorEl}
      handleClose={handleClose}
      open={openPopover === ActivePopover.CAMPAIGNS}
    >
      {partnerId || !hasPermission("read:advertisers") ? null : (
        <MenuItem
          button
          component={Link}
          selected={location.pathname.includes("/advertisers")}
          to="/advertisers"
        >
          <ListItemIcon>
            <ApartmentIcon />
          </ListItemIcon>
          <ListItemText primary="Advertisers" />
        </MenuItem>
      )}

      <MenuItem
        button
        component={Link}
        selected={location.pathname.includes("/brands")}
        to="/brands"
      >
        <ListItemIcon>
          <StoreIcon />
        </ListItemIcon>
        <ListItemText primary="Brands" />
      </MenuItem>

      <MenuItem
        button
        component={Link}
        selected={location.pathname.includes("/offers")}
        to="/offers"
      >
        <ListItemIcon>
          <LocalOfferOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Offers" />
      </MenuItem>

      {partnerId || !hasPermission("manage:account_pools") ? null : (
        <MenuItem
          button
          component={Link}
          selected={
            location.pathname.includes("/account-pools") ||
            location.pathname.includes("/offer-pools")
          }
          to="/account-pools"
        >
          <ListItemIcon>
            <AccountTreeIcon />
          </ListItemIcon>
          <ListItemText primary="Pools" />
        </MenuItem>
      )}

      {partnerId || !hasPermission("manage:smart_pixels") ? null : (
        <MenuItem
          button
          component={Link}
          selected={location.pathname.includes("/generator")}
          to="/generator"
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary="Smart Pixels" />
        </MenuItem>
      )}
    </SidenavPopover>
  );

  const renderReportsPopoverMenu = () => (
    <SidenavPopover
      anchorEl={anchorEl}
      handleClose={handleClose}
      open={openPopover === ActivePopover.REPORTS}
    >
      {partnerId || !hasPermission("read:reports:all") ? (
        <div>
          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/partner-daily")}
            to="/reports/partner-daily"
          >
            <ListItemText primary="Daily" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/partner-offer")}
            to="/reports/partner-offer"
          >
            <ListItemText primary="Offer" />
          </MenuItem>

          <SidenavSubId navOpen={navOpen} userType="partner" />

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/partner-creative")}
            to="/reports/partner-creative"
          >
            <ListItemText primary="Creative" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/partner-player")}
            to="/reports/partner-player"
          >
            <ListItemText primary="Player" />
          </MenuItem>
        </div>
      ) : (
        <div>
          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes(
              "/reports/admin-detailed-breakdown"
            )}
            to="/reports/admin-detailed-breakdown"
          >
            <ListItemText primary="Detailed Breakdown" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/admin-player")}
            to="/reports/admin-player"
          >
            <ListItemText primary="Player" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("reports/admin-daily")}
            to="/reports/admin-daily"
          >
            <ListItemText primary="Daily" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("reports/admin-monthly")}
            to="/reports/admin-monthly"
          >
            <ListItemText primary="Monthly" />
          </MenuItem>

          <SidenavSubId navOpen={navOpen} userType="admin" />

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/admin-cohort")}
            to="/reports/admin-cohort"
          >
            <ListItemText primary="Cohort" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/admin-partner")}
            to="/reports/admin-partner"
          >
            <ListItemText primary="Partner" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/admin-offer")}
            to="/reports/admin-offer"
          >
            <ListItemText primary="Offer" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/admin-campaign")}
            to="/reports/admin-campaign"
          >
            <ListItemText primary="Campaign" />
          </MenuItem>

          <MenuItem
            button
            component={Link}
            selected={location.pathname.includes("/reports/admin-creative")}
            to="/reports/admin-creative"
          >
            <ListItemText primary="Creative" />
          </MenuItem>
        </div>
      )}
    </SidenavPopover>
  );

  const renderTechPopoverMenu = () => (
    <SidenavPopover
      anchorEl={anchorEl}
      handleClose={handleClose}
      open={openPopover === ActivePopover.TECH}
    >
      {hasPermission("manage:clicks") && (
        <MenuItem
          button
          component={Link}
          selected={location.pathname.includes("/tech/import-csv")}
          to="/tech/import-csv"
        >
          <ListItemText primary="Import CSV" />
        </MenuItem>
      )}

      {hasPermission("read:clicks") && (
        <MenuItem
          button
          component={Link}
          selected={location.pathname.includes("/tech/conversion-management")}
          to="/tech/conversion-management"
        >
          <ListItemText primary="Manage Conversions" />
        </MenuItem>
      )}

      {hasPermission("manage:instance_settings") && (
        <MenuItem
          button
          component={Link}
          selected={location.pathname.includes("/tech/instance-settings")}
          to="/tech/instance-settings"
        >
          <ListItemText primary="Instance Settings" />
        </MenuItem>
      )}
    </SidenavPopover>
  );

  return (
    <List onMouseLeave={handleClose}>
      <ListItem
        button
        component={Link}
        divider
        onMouseOver={handleClose}
        selected={location.pathname.includes("/dashboard")}
        to="/dashboard"
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {partnerId || !hasPermission("read:users") ? null : (
        <ListItem
          button
          component={Link}
          divider
          onMouseOver={handleClose}
          selected={location.pathname.includes("/users")}
          to="/users"
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      )}

      <ListItem
        button
        divider={!campaignsOpen || !navOpen}
        id="CAMPAIGNS"
        onClick={() => navOpen && setCampaignsOpen(!campaignsOpen)}
        onMouseOver={handleOpen}
      >
        <ListItemIcon className={clsx({ [classes.navIcon]: !navOpen })}>
          <ListAltIcon />
        </ListItemIcon>
        {!navOpen && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
        <ListItemText primary="Campaigns" />
        {campaignsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      {!navOpen && renderCampaignsPopoverMenu()}

      <Collapse in={navOpen && campaignsOpen}>
        <List component="div" disablePadding>
          {partnerId || !hasPermission("read:advertisers") ? null : (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={location.pathname.includes("/advertisers")}
              to="/advertisers"
            >
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Advertisers" />
            </ListItem>
          )}

          <ListItem
            button
            className={classes.nestedListItem}
            component={Link}
            selected={location.pathname.includes("/brands")}
            to="/brands"
          >
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary="Brands" />
          </ListItem>

          <ListItem
            button
            className={classes.nestedListItem}
            component={Link}
            selected={location.pathname.includes("/offers")}
            to="/offers"
          >
            <ListItemIcon>
              <LocalOfferOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Offers" />
          </ListItem>

          {partnerId || !hasPermission("manage:account_pools") ? null : (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={
                location.pathname.includes("/account-pools") || 
                location.pathname.includes("/offer-pools")
              }
              to="/account-pools"
            >
              <ListItemIcon>
                <AccountTreeIcon />
              </ListItemIcon>
              <ListItemText primary="Pools" />
            </ListItem>
          )}

          {partnerId || !hasPermission("manage:smart_pixels") ? null : (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              divider
              selected={location.pathname.includes("/generator")}
              to="/generator"
            >
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="Smart Pixels" />
            </ListItem>
          )}
        </List>
      </Collapse>

      <ListItem
        button
        divider={!reportsOpen || !navOpen}
        id="REPORTS"
        onClick={() => navOpen && setReportsOpen(!reportsOpen)}
        onMouseOver={handleOpen}
      >
        <ListItemIcon className={clsx({ [classes.navIcon]: !navOpen })}>
          <TimelineIcon />
        </ListItemIcon>
        {!navOpen && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
        <ListItemText primary="Reports" />
        {reportsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      {!navOpen && renderReportsPopoverMenu()}

      <Collapse in={navOpen && reportsOpen}>
        <List component="div" disablePadding>
          {partnerId || !hasPermission("read:reports:all") ? (
            <>
              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/partner-daily")}
                to="/reports/partner-daily"
              >
                <ListItemText primary="Daily" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/partner-offer")}
                to="/reports/partner-offer"
              >
                <ListItemText primary="Offer" />
              </ListItem>

              <SidenavSubId navOpen={navOpen} userType="partner" />

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes(
                  "/reports/partner-creative"
                )}
                to="/reports/partner-creative"
              >
                <ListItemText primary="Creative" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/partner-player")}
                to="/reports/partner-player"
              >
                <ListItemText primary="Player" />
              </ListItem>
              <Divider />
            </>
          ) : (
            <>
              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes(
                  "/reports/admin-detailed-breakdown"
                )}
                to="/reports/admin-detailed-breakdown"
              >
                <ListItemText primary="Detailed Breakdown" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-player")}
                to="/reports/admin-player"
              >
                <ListItemText primary="Player" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-daily")}
                to="/reports/admin-daily"
              >
                <ListItemText primary="Daily" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-monthly")}
                to="/reports/admin-monthly"
              >
                <ListItemText primary="Monthly" />
              </ListItem>

              <SidenavSubId navOpen={navOpen} userType="admin" />

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-cohort")}
                to="/reports/admin-cohort"
              >
                <ListItemText primary="Cohort" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-partner")}
                to="/reports/admin-partner"
              >
                <ListItemText primary="Partner" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-offer")}
                to="/reports/admin-offer"
              >
                <ListItemText primary="Offer" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-campaign")}
                to="/reports/admin-campaign"
              >
                <ListItemText primary="Campaign" />
              </ListItem>

              <ListItem
                button
                className={classes.nestedListItem}
                component={Link}
                selected={location.pathname.includes("/reports/admin-creative")}
                to="/reports/admin-creative"
              >
                <ListItemText primary="Creative" />
              </ListItem>

              <Divider />
            </>
          )}
        </List>
      </Collapse>

      {partnerId ||
      !hasPermission("manage:clicks") ||
      !hasPermission("read:clicks") ? null : (
        <ListItem
          button
          divider={!techOpen || !navOpen}
          id="TECH"
          onClick={() => navOpen && setTechOpen(!techOpen)}
          onMouseOver={handleOpen}
        >
          <ListItemIcon className={clsx({ [classes.navIcon]: !navOpen })}>
            <BuildIcon />
          </ListItemIcon>
          {!navOpen && (
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          )}
          <ListItemText primary="Tech" />
          {techOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      )}

      {!navOpen && renderTechPopoverMenu()}

      <Collapse in={navOpen && techOpen}>
        <List component="div" disablePadding>
          {hasPermission("manage:clicks") && (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={location.pathname.includes("/import-csv")}
              to="/tech/import-csv"
            >
              <ListItemText primary="Import CSV" />
            </ListItem>
          )}

          {hasPermission("read:clicks") && (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={location.pathname.includes("/conversion-management")}
              to="/tech/conversion-management"
            >
              <ListItemText primary="Manage Conversions" />
            </ListItem>
          )}

          {hasPermission("manage:instance_settings") && (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={location.pathname.includes("/instance-settings")}
              to="/tech/instance-settings"
            >
              <ListItemText primary="Instance Settings" />
            </ListItem>
          )}
        </List>
      </Collapse>

      {!partnerId  && (readAllAdjustments || readPayments) && (
        <ListItem
          button
          divider={!accountingOpen || !navOpen}
          id="ACCOUNTING"
          onClick={() => navOpen && setAccountingOpen(!accountingOpen)}
          onMouseOver={handleOpen}
        >
          <ListItemIcon className={clsx({ [classes.navIcon]: !navOpen })}>
            <AccountBalanceIcon />
          </ListItemIcon>
          {!navOpen && (
            <ListItemIcon>
              <ChevronRightIcon />
            </ListItemIcon>
          )}
          <ListItemText primary="Accounting" />
          {accountingOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      )}

      {!navOpen && renderAccountingPopoverMenu()}

      <Collapse in={navOpen && accountingOpen}>
        <List component="div" disablePadding>
          {readAllAdjustments && (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={location.pathname.includes("/adjustments")}
              to="/adjustments"
            >
              <ListItemIcon>
                <FormatListNumberedIcon />
              </ListItemIcon>
              <ListItemText primary="Adjustments" />
            </ListItem>
          )}
          {readPayments && (
            <ListItem
              button
              className={classes.nestedListItem}
              component={Link}
              selected={location.pathname.includes("/payments")}
              to="/payments"
            >
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Payments" />
            </ListItem>
          )}
        </List>
      </Collapse>

      <ListItem
        button
        id="ACCOUNT"
        onClick={() => navOpen && setAccountOpen(!accountOpen)}
        onMouseOver={handleOpen}
      >
        <ListItemIcon className={clsx({ [classes.navIcon]: !navOpen })}>
          <SettingsIcon />
        </ListItemIcon>
        {!navOpen && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
        <ListItemText primary="Account" />
        {accountOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      {!navOpen && renderAccountPopoverMenu()}

      <Collapse in={navOpen && accountOpen}>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.nestedListItem}
            component={Link}
            selected={location.pathname.includes("/settings")}
            to="/settings"
          >
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>

          <ListItem
            button
            className={classes.nestedListItem}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            <ListItemIcon>
              <ClearIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Collapse>
    </List>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  navIcon: {
    minWidth: "fit-content"
  },
  nestedListItem: {
    paddingLeft: spacing(4)
  },
  popover: {
    pointerEvents: "auto"
  },
  root: {
    pointerEvents: "none"
  },
  subNestedListItem: {
    paddingLeft: spacing(8)
  }
}));

export default Sidenav;

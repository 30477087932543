import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import {
  Add as AddIcon,
  FiberManualRecordTwoTone
} from "@material-ui/icons";
import ConfirmationAlert from "../ConfirmationAlert";
import InputBrandSearch from "../InputBrandSearch";
// import InputAdvertiserSearch from "../InputAdvertiserSearch";

import { useMutation } from "@apollo/client";
import {
  Offers_offers_edges_node_brand
} from "../../generated/Offers";
import {
  Brands_brands_edges_node
} from "../../generated/Brands";
import {
  OfferPool_offerPool_offerPool_requirements as IOfferPoolRequirement,
} from "../../generated/OfferPool";
import {
  OfferPools_offerPools_edges_node_brand,
  OfferPools_offerPools_edges_node_requirements,
  OfferPools_offerPools_edges_node_requirements_offer
} from "../../generated/OfferPools";
import {
  CreateOfferPool,
  CreateOfferPoolVariables
} from "../../generated/CreateOfferPool";
import CreateOfferPoolMutation from "../../mutations/CreateOfferPoolMutation";
import {
  UpdateOfferPool,
  UpdateOfferPoolVariables
} from "../../generated/UpdateOfferPool";
import UpdateOfferPoolMutation from "../../mutations/UpdateOfferPoolMutation";
import { OfferPoolRequirementInput } from "../../generated/globalTypes";
// import { Brand_brand_brand_advertiser } from "../../generated/Brand";
import OfferPoolFormOfferRequirementGroup from "../OfferPoolFormOfferRequirementGroup/OfferPoolFormOfferRequirementGroup";

export interface IOfferPoolRequirementsGroup {
  offer: OfferPools_offerPools_edges_node_requirements_offer
  requirements: IOfferPoolRequirement[]
}

export interface IOfferRequirementsMap {
  [key: number]: IOfferPoolRequirementsGroup
}

interface IOfferPoolFormProps {
  type: string;
  onClose: () => void;
  open: boolean;
}

interface IOfferPoolFormCreateProps extends IOfferPoolFormProps {
  id?: undefined;
  name?: undefined;
  active?: boolean;
  archived?: boolean;
  brand?: OfferPools_offerPools_edges_node_brand,
  requirements?: OfferPools_offerPools_edges_node_requirements[]
}

interface IOfferPoolFormUpdateProps extends IOfferPoolFormProps {
  id: number;
  name: String;
  active: boolean,
  archived: boolean,
  brand: OfferPools_offerPools_edges_node_brand,
  requirements: OfferPools_offerPools_edges_node_requirements[]
}

export const groupRequirementsByOffer = (requirements: IOfferPoolRequirement[]) => {
  const reducer = (acc: IOfferRequirementsMap, requirement: IOfferPoolRequirement) => {
    if (!acc[requirement.offer.id]) {
      acc[requirement.offer.id] = {
        offer: requirement.offer,
        requirements: []
      };
    }
    acc[requirement.offer.id].requirements.push(requirement);
    return acc;
  }
  return requirements.reduce(reducer, {} as IOfferRequirementsMap)
}

const OfferPoolForm = ({
  type,
  onClose,
  open,
  id,
  name,
  active,
  archived,
  brand,
  requirements
}: IOfferPoolFormCreateProps|IOfferPoolFormUpdateProps) => {

  const classes = useClasses();

  // Internal state
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmDeactivateOpen, setConfirmDeactivateOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  
  // Offer data
  const [offerPoolName, setOfferPoolName] = useState<string>(name as string || '');
  // const [offerPoolAdvertiser, setOfferPoolAdvertiser] = useState<number>();
  const [offerPoolBrand, setOfferPoolBrand] = useState<number>();
  const [offerPoolOffersAndRequirements, setOfferPoolOffersAndRequirements] = useState<IOfferRequirementsMap>({});
  const [dirtyId, setDirtyId] = useState<number>();

  const [upsertOfferPool, { loading }] = useMutation<CreateOfferPool | UpdateOfferPool>(
    type === "create" ? CreateOfferPoolMutation : UpdateOfferPoolMutation
  );

  const handleAddOfferRequirementGroup = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setDirty(true);
    setOfferPoolOffersAndRequirements(() => {
      const tempId = Math.max(...Object.keys(offerPoolOffersAndRequirements).map((key) => parseInt(key))) + 1;
      setDirtyId(tempId);
      return {
        ...offerPoolOffersAndRequirements,
        [tempId]: {
          offer: {} as OfferPools_offerPools_edges_node_requirements_offer,
          requirements: [{}]
        }
      } as IOfferRequirementsMap
    })    
  }

  const handleRemoveOfferRequirementGroup = (id: number) => {
    return () => {
      setDirty(true);
      setOfferPoolOffersAndRequirements(() => {
        const newState = {...offerPoolOffersAndRequirements};
        delete newState[id];
        return newState;
      })
    }
  }

  const closeAndReset = () => {
    setDirty(false);
    onClose();
  }

  const handleDiscard = () => {
    setConfirmOpen(false);
    closeAndReset();
  }

  const handleClose = () => {
    dirty ? setConfirmOpen(true) : closeAndReset()
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    let variables: CreateOfferPoolVariables | UpdateOfferPoolVariables;

    const getRequirements = () => {
      return Object.values(offerPoolOffersAndRequirements).reduce((requirements, offerPool: IOfferPoolRequirementsGroup) => {
        const opRequirements = (offerPool.requirements || []).map((requirement) => ({
          offerId: offerPool.offer.id,
          requirementType: requirement.requirementType,
          requirementValue: requirement.requirementValue
        })) as OfferPoolRequirementInput[];
        return [...requirements, ...opRequirements]
      }, [] as OfferPoolRequirementInput[]);
    }

    if (type === "create") {
      variables = {
        brandId: offerPoolBrand as number,
        name: offerPoolName as string,
        requirements: getRequirements()
      };
    } else {
      variables = {
        id: id as number,
        name: offerPoolName as string,
        requirements: getRequirements()
      };
    }
    
    upsertOfferPool({
      variables,
    }).then(() => {
      closeAndReset();
    })
  }

  useEffect(() => {
    setOfferPoolName(name as string || '');
    setOfferPoolBrand(brand ? brand.id : undefined);
    // setOfferPoolAdvertiser(brand ? brand.advertiser.id : undefined);
    setOfferPoolOffersAndRequirements(groupRequirementsByOffer(requirements || []));
  }, [name, brand, requirements])

  return (<>
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle className={classes.dialogTitle}>
          {`${type === 'create' ? 'Create' : 'Update'} Offer Pool`}
          {active && !archived && <Chip
            variant="outlined"
            size="small"
            label="Live"
            color={active ? 'primary' : 'default'}
            icon={<FiberManualRecordTwoTone />} />}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.formTitle} color="textSecondary">
            Pool Details
          </Typography>
          <TextField
            autoFocus
            id="name"
            label="Offer Pool Name"
            className={classes.formControl}
            fullWidth
            required
            onChange={(event) => {
              setDirty(true);
              setOfferPoolName(event.target.value);
            }}
            value={offerPoolName}
          />
          {/* <InputAdvertiserSearch
            label="Search for advertiser..."
            defaultValue={brand ? brand.advertiser as Brand_brand_brand_advertiser : undefined}
            className={classes.formControl}
            disabled={false}
            onSelect={(id) => {
              setDirty(true);
              setOfferPoolAdvertiser(id);
            }}
          /> */}
          <InputBrandSearch
            label="Search for brand..."
            defaultValue={brand as Offers_offers_edges_node_brand || undefined}
            className={classes.formControl}
            disabled={false}
            onSelect={(brand: Brands_brands_edges_node | undefined) => {
              setDirty(true);
              setOfferPoolBrand(brand ? brand.id : undefined);
            }}
          />
        </DialogContent>
        <DialogContent>
          <Typography className={classes.formTitle} color="textSecondary">
            Pool Offers
          </Typography>
          <div className={classes.cardOverflowContainer}>
            {Object.values(offerPoolOffersAndRequirements).length ? Object.values(offerPoolOffersAndRequirements).map((offerPoolGroup: IOfferPoolRequirementsGroup, groupId: number) => (
              <OfferPoolFormOfferRequirementGroup
                key={offerPoolGroup.offer.id}
                brandId={offerPoolBrand}
                offer={offerPoolGroup.offer}
                requirements={offerPoolGroup.requirements}
                onChange={(offerPoolGroup: IOfferPoolRequirementsGroup) => {
                  setDirty(true);
                  if (dirtyId && Object.keys(offerPoolOffersAndRequirements).includes(dirtyId.toString())) {
                    delete offerPoolOffersAndRequirements[dirtyId];
                  }
                  setOfferPoolOffersAndRequirements(() => ({
                    ...offerPoolOffersAndRequirements,
                    [offerPoolGroup.offer.id]: offerPoolGroup
                  }))

                }}
                onDelete={handleRemoveOfferRequirementGroup(groupId)}
              />
            )) : (
                <Typography color="textSecondary" align="center">
                  No offers added to this pool.
                </Typography>
            )}
          </div>
          <div className={classes.formButton}>
            <Button fullWidth variant="outlined" onClick={handleAddOfferRequirementGroup}>
              <AddIcon />
              Offer
            </Button>
          </div>
        </DialogContent>
        {
          type === "update" && (
            <DialogContent>
              <Typography className={classes.formTitle} color="textSecondary">
                Pool Status
              </Typography>
              <div className={classes.formButtons}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="default"
                  onClick={() => setConfirmDeactivateOpen(true)}
                >
                  {active ? "Deactivate" : "Activate"} Pool
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="secondary"
                  onClick={() => setConfirmDeleteOpen(true)}
                >
                  Delete
                </Button>
              </div>
            </DialogContent>
          )
        }
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>Cancel</Button>
          <Button type="submit" color="primary" variant="contained" disabled={loading}>
            {type === 'create' ? 'Create' : 'Update'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>

    <ConfirmationAlert
      title="Unsaved Changes"
      content="Closing this form will lose any unsaved progress."
      open={confirmOpen}
      positiveAction="Discard"
      onNegative={() => setConfirmOpen(false)}
      onPositive={handleDiscard}
    />

    <ConfirmationAlert
      title="Delete Offer Pool"
      content={`Are you sure you want to delete the offer pool "${offerPoolName}"? This action cannot be undone.`}
      open={confirmDeleteOpen}
      positiveAction="Delete"
      onNegative={() => setConfirmDeleteOpen(false)}
      onPositive={() => {}}
    />

    <ConfirmationAlert
      title="Deactivate Offer Pool"
      content={`Are you sure you want to deactivate the offer pool "${offerPoolName}"?`}
      open={confirmDeactivateOpen}
      positiveAction="Deactivate"
      onNegative={() => setConfirmDeactivateOpen(false)}
      onPositive={() => {}}
    />
  </>);
}

const useClasses = makeStyles(({ spacing, palette }) => ({
  dialogTitle: {
    '& h2': {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    }
  },
  formTitle: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  formControl: {
    paddingBottom: spacing(2)
  },
  formButton: {
    paddingTop: spacing(2)
  },
  formButtons: {
    display: "flex",
    gap: spacing(1),
    justifyContent: "space-between"
  },
  cardOverflowContainer: {
    maxHeight: 400,
    overflowY: "auto",
    padding: spacing(1),
    backgroundColor: palette.grey[50],
    borderRadius: 4,
    border: `1px solid ${palette.grey[100]}`,
    borderTopColor: palette.grey[200]
  }
}));

export default OfferPoolForm;
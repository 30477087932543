import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  ConversionKeys as IConversionKeysQuery,
  ConversionKeys_conversionKeys
} from "../../generated/ConversionKeys";
import ConversionKeysQuery from "../../queries/ConversionKeysQuery";

import ConversionKeyList from "../../components/ConversionKeyList";

const ConversionKeyListContainer = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const page = parseInt(queryParams.get("page") || "") || undefined;

  const { loading, error, data } = useQuery<IConversionKeysQuery>(
    ConversionKeysQuery,
    { variables: { page } }
  );

  const conversionKeysData = (() => {
    if (data && data.conversionKeys) {
      return data.conversionKeys;
    } else {
      const defaultData: ConversionKeys_conversionKeys = {
        __typename: "ConversionKeysConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        },
        totalCount: 0
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    if (!conversionKeysData.pageInfo.hasNextPage) return;
    history.push({
      pathname: history.location.pathname,
      search: `?page=${(page || 1) + 1}`
    });
  };

  const onPreviousPage = () => {
    if (!conversionKeysData.pageInfo.hasPreviousPage) return;
    history.push({
      pathname: history.location.pathname,
      search: `?before=${(page || 1) - 1}`
    });
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <ConversionKeyList
        hasNextPage={conversionKeysData.pageInfo.hasNextPage}
        hasPreviousPage={conversionKeysData.pageInfo.hasPreviousPage}
        conversionKeys={conversionKeysData.edges.map(edge => edge.node)}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  partnerView: {
    backgroundColor: "#79B9EA",
    color: "white",
    display: "flex",
    justifyContent: "center"
  }
}));

export default ConversionKeyListContainer;

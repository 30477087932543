import React, { useState } from "react";
import { Breadcrumbs, Button, Card, CardContent, Chip, Divider, Grid, Link, List, ListItem, ListItemText, makeStyles, Paper, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import { Add as AddIcon, ChevronLeft, FiberManualRecordTwoTone } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import OfferPoolForm, {
  groupRequirementsByOffer,
  IOfferPoolRequirementsGroup
} from "../OfferPoolForm/OfferPoolForm";
import {
  OfferPool_offerPool_offerPool as IOfferPool,
  OfferPool_offerPool_offerPool_requirements as IOfferPoolRequirement
} from "../../generated/OfferPool";
import { format } from "date-fns";

interface IOfferPoolShowProps {
  offerPool: IOfferPool;
}

const OfferPoolShow = ({
  offerPool
}: IOfferPoolShowProps) => {
  const history = useHistory();
  const classes = useStyles();

  const [offerPoolEditOpen, setOfferPoolEditOpen] = useState(false);

  const requirementsGroupedByOffer = groupRequirementsByOffer(offerPool.requirements);

  const renderOfferListIem = (label: string, value: any) => {
    return (
      <div className={classes.cardListItem}>
        <Typography component="dt" variant="body1">{label}</Typography>
        <Typography component="dd" variant="body1">{value}</Typography>
      </div>
    )
  }

  return (<>
    <Paper>
      <div className={classes.header}>
        <div className={classes.headerItem}>
          <Breadcrumbs className={classes.breadcrumbs}>
            <Link underline="hover" color="inherit" onClick={(e) => history.push('/offer-pools')} >
              <ChevronLeft/>
              Offer Pools
            </Link>
            <Typography color="textPrimary">{offerPool?.name}</Typography>
          </Breadcrumbs>
        </div>
        <div className={classes.headerItem}>
          <Chip
            variant="outlined"
            size="small"
            label="Live"
            color="primary"
            icon={<FiberManualRecordTwoTone/>}
          />
          <Button color="primary" variant="contained" onClick={() => setOfferPoolEditOpen(true)}>
            <AddIcon />
            Edit
          </Button>
        </div>
      </div>
      <Divider />
      <div className={classes.gRoot}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2}>
            <List>
              <ListItem button>
                <ListItemText primary="Details"/>
              </ListItem>
              <ListItem button>
                <ListItemText primary="Offers" /></ListItem>
              <ListItem button>
                <ListItemText primary="Status" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={10}>
            <div className={classes.cardContainer}>
              <Card variant="outlined" className={classes.card}>  
                <CardContent>
                  <Typography className={classes.cardTitle} color="textSecondary">
                    Details
                  </Typography>
                  <dl className={classes.cardList}>
                    { renderOfferListIem('Name', offerPool?.name) }
                    { renderOfferListIem('Advertiser', offerPool?.brand.advertiser.name) } 
                    { renderOfferListIem('Brand', offerPool?.brand.name) }
                  </dl>
                </CardContent>
              </Card>

              <Card variant="outlined" className={classes.card}>  
                <CardContent>
                  <Typography className={classes.cardTitle} color="textSecondary">
                    Offers
                  </Typography>
                  {
                    offerPool?.requirements.length > 0 ? (
                    <Table className={ classes.cardListItemTable } size="small">
                      <TableBody>
                        {[...Object.values(requirementsGroupedByOffer)].map((group: IOfferPoolRequirementsGroup) => (
                          <TableRow key={group.offer.id}>
                            <TableCell>
                              <Typography className="offerName" component="strong" variant="inherit">{group.offer.name}</Typography>
                            </TableCell>
                            <TableCell>
                              {
                                group.requirements.map((req: IOfferPoolRequirement) => req.requirementType)
                                .filter((requirementType, index, self) => self.indexOf(requirementType) === index)
                                .join(', ')
                              }
                            </TableCell>
                            <TableCell>{group.requirements.map((req: IOfferPoolRequirement ) => req.requirementValue).join(', ')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    ) : (
                      <Typography variant="subtitle2" color="textSecondary" align="center">
                        No offers added
                      </Typography>
                    )
                  }
                </CardContent>
              </Card>

              <Card variant="outlined" className={classes.card}>  
                <CardContent>
                  <Typography className={classes.cardTitle} color="textSecondary">
                    Status
                  </Typography>
                  <dl className={classes.cardList}>
                    { renderOfferListIem('Created', format(new Date(offerPool.createdAt), 'MM/dd/yyyy HH:mmaa')) }
                    { renderOfferListIem('Last Updated', format(new Date(offerPool.updatedAt), 'MM/dd/yyyy HH:mmaa')) }
                    { renderOfferListIem('Status', <Chip
                        variant="outlined"
                        size="small"
                        label="Live"
                        color={offerPool.active ? 'primary' : 'default'}
                        icon={<FiberManualRecordTwoTone/>}
                      />) }
                  </dl>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
      </Paper>
      <OfferPoolForm
        type="update"
        onClose={() => setOfferPoolEditOpen(false)}
        open={offerPoolEditOpen}
        id={parseInt(offerPool.id, 10)}
        name={offerPool.name}
        active={offerPool.active}
        archived={offerPool.archived}
        brand={offerPool.brand}
        requirements={offerPool.requirements}
      />
    </>
  )
}

const useStyles = makeStyles(({ typography, spacing, palette }) => ({
  
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: spacing(2)
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(2),
  },
  breadcrumbs: {
    paddingBottom: spacing(1),
    '& a, & li': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& a': {
      cursor: 'pointer'
    }
  },
  gRoot: {
    flexGrow: 1
  },
  cardContainer: {
    padding: spacing(2),
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: palette.grey[200]
  },
  card: {
    marginBottom: spacing(2)
  },
  cardTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  cardList: {
    width: '100%'
  },
  cardListItem: {
    display: 'flex',
    gap: spacing(1),
    marginBottom: spacing(1),
    '& > dt': {
      fontWeight: 'bold',
      minWidth: '10%'
    }
  },
  cardListItemTable: {
    width: 'inherit'
  }
}))

export default OfferPoolShow;
import { makeStyles, Paper, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import OfferPoolForm from '../OfferPoolForm/OfferPoolForm';
import OfferPoolListItem from '../OfferPoolListItem/OfferPoolListItem';
import {
  OfferPools_offerPools_edges_node
} from '../../generated/OfferPools';

export interface IOfferPoolListProps {
  loading: boolean;
  offerPools: OfferPools_offerPools_edges_node[];
  handleSearch: (value?: string) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const OfferPoolList = ({
  loading,
  offerPools
}: IOfferPoolListProps) => {
  const styles = useStyles();
  const [offerPoolCreateOpen, setOfferPoolCreateOpen] = useState(false);
  return (<>
    <Paper className={styles.paper}>
      <div className={styles.header}>
        <Typography variant="h5">Offer Pools</Typography>
        <Button
          color="primary"
          onClick={() => setOfferPoolCreateOpen(true)}
          variant="contained"
        >
          <AddIcon />
          Offer Pool
        </Button>
      </div> 
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell width={90} align='center'># Offers</TableCell>
            <TableCell>Properties</TableCell>
            <TableCell width={150} align='right'>Last Updated</TableCell>
            <TableCell width={80} align='right'>Actions</TableCell>
            <TableCell width={120} align='right'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offerPools.length ? offerPools.map(offerPool => (
            <OfferPoolListItem
              offerPool={offerPool}
              key={offerPool.id}
            />
          )) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
                {loading ? <CircularProgress thickness={6} color="primary"/> : "No offer pools found"}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <OfferPoolForm
        type="create"
        onClose={() => setOfferPoolCreateOpen(false)}
        open={offerPoolCreateOpen}
      />
    </Paper>
  </>)
};

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing(1)
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
}));

export default OfferPoolList;
import React, { useState } from 'react'
import { FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core'
import { OfferPoolRequirementType } from '../../generated/globalTypes'
import {
  OfferPool_offerPool_offerPool_requirements as IOfferPoolRequirement,
} from '../../generated/OfferPool';
import { Remove } from '@material-ui/icons';
import { useDebouncedCallback } from 'use-debounce';

interface IOfferPoolFormOfferRequirementSetProps {
  index?: number
  requirement?: IOfferPoolRequirement,
  onDelete: () => void,
  onChange: (values: any) => void
}
const OfferPoolFormOfferRequirementSet = ({
  index,
  requirement,
  onChange,
  onDelete
}: IOfferPoolFormOfferRequirementSetProps) => {
  const classes = useStyles();
  const [requirementTypeState, setRequirementTypeState] = useState<OfferPoolRequirementType | undefined>(requirement?.requirementType as OfferPoolRequirementType);
  const [requirementValueState, setRequirementValueState] = useState<string>(requirement?.requirementValue as string);
  const [emitDebouncedOnChange] = useDebouncedCallback(() => {
    onChange({
      requirementType: requirementTypeState as OfferPoolRequirementType,
      requirementValue: requirementValueState as string
    })
  }, 300);
  return (
    <div className={classes.root}>
      <FormControl variant="standard">
        <InputLabel id={`requirement-${index}-type-label`}>Requirement Type</InputLabel>
        <Select
          label="Requirement Type"
          labelId={`requirement-${index}-type-label`}
          onChange={() => {
            setRequirementTypeState(requirementTypeState as OfferPoolRequirementType);
            emitDebouncedOnChange();
          }}
          value={requirementTypeState as OfferPoolRequirementType}
        >
          <MenuItem value={undefined} disabled={true}>Requirement Type</MenuItem>
          {Object.keys(OfferPoolRequirementType).map((requirementType, ri) => (
            <MenuItem value={requirementType} key={ri}>{requirementType}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        label="Requirement Value"
        value={requirementValueState}
        onChange={(event) => {
          setRequirementValueState(event.target.value as string);
          emitDebouncedOnChange();
        }}
      />
      <IconButton title="Remove this requirement" size="small" onClick={onDelete}>
        <Remove />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "calc(40% - 1em) calc(60% - 48px) 48px",
    gap: "0.5em",
    margin: "1em 0 0.5em"
  }
}))

export default OfferPoolFormOfferRequirementSet;
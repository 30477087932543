import React, { FormEvent, useState } from 'react';
import { Button, Card, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Add, Close } from '@material-ui/icons';
import { 
  IOfferPoolRequirementsGroup
} from '../OfferPoolForm/OfferPoolForm';
import InputOfferSearch from '../InputOfferSearch/InputOfferSearch';
import {
  OfferPool_offerPool_offerPool_requirements as IOfferPoolRequirement,
} from '../../generated/OfferPool';
import OfferPoolFormOfferRequirementSet from '../OfferPoolFormOfferRequirementSet/OfferPoolFormOfferRequirementSet';

interface IOfferPoolFormOfferRequirementGroupProps extends IOfferPoolRequirementsGroup{
  brandId?: number
  onChange: (requirements: IOfferPoolRequirementsGroup) => void
  onDelete?: () => void;
}

const OfferPoolFormOfferRequirementGroup = ({
  offer,
  brandId,
  requirements,
  onChange,
  onDelete
}: IOfferPoolFormOfferRequirementGroupProps) => {
  const classes = useStyles();
  const [offerRequirements, setOfferRequirements] = useState<IOfferPoolRequirement[]>(
    requirements as IOfferPoolRequirement[]
  )

  const emitOnChange = () => onChange({
    offer,
    requirements: offerRequirements
  } as IOfferPoolRequirementsGroup);

  const onAddRequirement = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOfferRequirements([...offerRequirements, {} as IOfferPoolRequirement]);
    emitOnChange();
  }

  const onDeleteRequirement = (index: number) => {
    setOfferRequirements(offerRequirements.filter((_, i) => i !== index));
    emitOnChange();
  }

  return (
    <Card variant="outlined" className={classes.root}>
      <div className={classes.header}>
        <IconButton title="Remove this offer" size="small" onClick={onDelete}>
          <Close/>
        </IconButton>
      </div>
      <InputOfferSearch
        label="Offer"
        brandId={brandId}
        defaultValue={offer}
        onSelect={(id) => {
          // @TODO
        }}
      />
      {
        offerRequirements.length> 0 ? offerRequirements.map((requirement, index) => (
          <OfferPoolFormOfferRequirementSet
            key={index}
            index={index}
            requirement={requirement}
            onChange={({requirementType, requirementValue}) => {
              const newRequirements = [...offerRequirements];
              newRequirements[index] = {
                ...requirement,
                requirementType,
                requirementValue
              } as IOfferPoolRequirement;
              setOfferRequirements(newRequirements);
              emitOnChange();
            }}
            onDelete={() => onDeleteRequirement(index)}
          />
        )) : (
          <div style={{ padding: 16 }}>
            <Typography color="textSecondary" align="center">
              No requirements added to this offer.
            </Typography>
          </div>
        )
      }
      <Button fullWidth onClick={onAddRequirement}>
        <Add />
        Requirement
      </Button>
    </Card>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(1),
    marginBottom: spacing(1),
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
  }
}))

export default OfferPoolFormOfferRequirementGroup;

import React, { ChangeEvent } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import OffersQuery from '../../queries/OffersQuery';
import { Offers as IOffersQuery } from '../../generated/Offers';

// Simplified offer interface for the search input
interface IInputOfferSearchOffer {
  id: number;
  name: string;
}

interface IInputOfferSearchProps {
  brandId?: number;
  className?: string;
  defaultValue?: IInputOfferSearchOffer;
  disabled?: boolean;
  label?: string;
  onSelect: (id?: number) => void;
}

const InputOfferSearch = ({
  brandId,
  label,
  className,
  disabled,
  defaultValue,
  onSelect
}: IInputOfferSearchProps) => {

  const { data, loading, refetch } = useQuery<IOffersQuery>(OffersQuery, {
    variables: {
      brandId,
      limit: 50
    }
  });

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);
  const handleSearch = (value: string) => {
    debouncedRefetch({
      brandId,
      limit: 50,
      search: value || undefined
    });
  };

  const handleSelect = (offer?: IInputOfferSearchOffer) => {
    if (offer) {
      onSelect(offer.id);
    } else {
      onSelect(undefined);
      handleSearch("");
    }
  };

  const handleChange = (_: ChangeEvent<{}>, offer: any) => {
    handleSelect(offer);
  }

  const options = data?.offers?.edges.map(edge => edge.node) || [];

  return (
    <Autocomplete
      defaultValue={defaultValue}
      className={className}
      disabled={disabled}
      loading={loading}
      options={options}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={handleChange}
      onInputChange={(_, value) => handleSearch(value)}
      renderInput={params => <TextField {...params} fullWidth label={label} />}
      noOptionsText={ "No offers available" }
    />
  )
};

export default InputOfferSearch;

import React, { MouseEvent, useState } from 'react';
import OfferPoolForm from '../OfferPoolForm/OfferPoolForm';
import { format } from 'date-fns';
import { TableRow, TableCell, makeStyles, IconButton, Chip, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { OfferPools_offerPools_edges_node } from '../../generated/OfferPools';
import { FiberManualRecordTwoTone, Edit as EditIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  tableRow: {
    cursor: "pointer"
  }
}));

export interface IOfferPoolListItemProps { 
  offerPool: OfferPools_offerPools_edges_node
}

const OfferPoolListItem = ({
  offerPool
}: IOfferPoolListItemProps) => {
  const history = useHistory();
  const styles = useStyles();
  const [offerPoolUpdateOpen, setOfferPoolUpdateOpen] = useState(false);
  const onEditClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOfferPoolUpdateOpen(true);
  };

  const offerRequirementCount = () => offerPool.requirements.reduce((offers, offerRequirement) => {
    if (!offers.includes(offerRequirement.offer.id)) {
      offers.push(offerRequirement.offer.id);
    }
    return offers;
  }, [] as number[]).length

  const offerRequirementTypes = () => offerPool.requirements.length === 0
    ? <Typography variant="body2">&mdash;</Typography>
    : offerPool.requirements.reduce((requirementTypes, offerRequirement) => {
    if (!requirementTypes.includes(offerRequirement.requirementType)) {
      requirementTypes.push(offerRequirement.requirementType);
    }
    return requirementTypes;
  }, [] as string[]).join(", ")

  return (
    <>
      <TableRow
        className={styles.tableRow}
        hover
        onClick={() => history.push(`/offer-pools/${offerPool.id}`)}
      >
        <TableCell>
          <Typography variant="body2">{offerPool.name}</Typography>
        </TableCell>
        <TableCell width={90} align='center'>
          <Typography variant="body2">{offerRequirementCount()}</Typography>
        </TableCell>
        <TableCell>{offerRequirementTypes()}</TableCell>
        <TableCell width={150} align='right'>
          <Typography variant="body2">{format(new Date(offerPool.updatedAt), "MM/dd/yyyy")}</Typography>
          <Typography variant="body2" color="textSecondary">{format(new Date(offerPool.updatedAt), "HH:mmaa")}</Typography>
        </TableCell>
        <TableCell width={80} align='right'>
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
        </TableCell>
        <TableCell width={100} align='right'>
          {offerPool.active && !offerPool.archived && <Chip
            variant="outlined"
            size="small"
            label="Live"
            color={offerPool.active ? 'primary' : 'default'}
            icon={<FiberManualRecordTwoTone />} />}
          {offerPool.archived && !offerPool.active && <Chip
            label="Archived"
            color="default"
            size="small"
          />}
        </TableCell>

        <OfferPoolForm
          type="update"
          onClose={() => setOfferPoolUpdateOpen(false)}
          open={offerPoolUpdateOpen}
          id={parseInt(offerPool.id, 10)}
          name={offerPool.name}
          active={offerPool.active}
          archived={offerPool.archived}
          brand={offerPool.brand}
          requirements={offerPool.requirements}
        />
      </TableRow>
    </>
  )
};

export default OfferPoolListItem;
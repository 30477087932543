import { gql } from "@apollo/client";
const OfferPoolQuery = gql`
  query OfferPool(
    $offerPoolId: Int!
  ) {
    offerPool(offerPoolId: $offerPoolId) {
      offerPool {
        id
        active
        archived
        name
        createdAt
        updatedAt
        brand {
          id
          name
          trackingLink
          trackingLinkAppend
          advertiser {
            id
            name
          }
        }
        requirements {
          id
          requirementType
          requirementValue
          offer {
            id
            name
          }
        }
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default OfferPoolQuery;